import React from 'react'
import { openUrlInNewTab } from '../utils/Contants'

function PortfolioCard({portfolio}) {

  return (
    <div className='portfolio-card-container' style={{display: 'flex', flexDirection: 'column'}}>
        <div style={{width: '100%', height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
            <img className='portfolio-card-img' style={{width: portfolio.type == 'app' ? 'auto' : 'auto'}} src={portfolio.image}></img>
        </div>
        <div 
        onClick={() => openUrlInNewTab(portfolio.url)}
        className='portfolio-link'>
            <div
            style={{color: 'white', fontWeight: 'normal', fontSize: '1.5rem', whiteSpace: 'nowrap', paddingRight: 20}}>
                {portfolio.title}
            </div>
            <div>
                <i className='portfolio-link-icon fa-thin fa-right-from-bracket'></i>
            </div>
        </div>
    </div>
  )
}

export default PortfolioCard