import React from 'react';
import './Moon.scss'; // Importe le fichier Sass

const Moon = () => {
  return (
    <div className="moon-container">
      <div className="moon">
        <div className="crater crater-1"></div>
        <div className="crater crater-2"></div>
        <div className="crater crater-3"></div>
        <div className="crater crater-4"></div>
      </div>
    </div>
  );
};

export default Moon;