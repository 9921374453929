import React, { useEffect, useState, useRef } from "react";
import NavBar from "./components/NavBar";
import CreatePhoneApp from "./components/CreatePhoneApp";
import CreateWebSite from "./components/CreateWebSite";
import useIntersectionObserver from "./hook/useIntersectionObserver";
import ReviewsSection from "./components/ReviewsSection";
import PortfolioSection from "./components/PortfolioSection";
import ScrollLine from "./components/ScrollLine";
import Moon from "./components/Moon";
import PricingSection from "./components/PricingSection";
import ContactMeSection from "./components/ContactMeSection";


function App() {

  const [numberRainDrop, setNumberRainDrop] = useState(150)
  const [rainDrops, setRainDrops] = useState([]); // État pour stocker les gouttes de pluie

    
  const phoneAppRef = useRef(null);
  const webSiteRef = useRef(null);
  const titleRef = useRef(null);
  const reviewsSectionRef = useRef(null);
  const portfiolioSectionRef = useRef(null);
  const pricingSectionRef = useRef(null)
  const contactMeSectionRef = useRef(null)

  const [menuNavBar, setMenuNavBar] = useState([
    { id: 1, name: 'phone', selected: true, icon: 'fa-thin fa-mobile-notch', ref: phoneAppRef },
    { id: 2, name: 'laptop', selected: false, icon: 'fa-thin fa-laptop',  ref: webSiteRef },
    { id: 3, name: 'reviews', selected: false, icon: 'fa-thin fa-star-sharp', ref: reviewsSectionRef },
    { id: 4, name: 'portfolio', selected: false, icon: 'fa-thin fa-images', ref: portfiolioSectionRef },
    { id: 5, name: 'pricing', selected: false, icon: 'fa-thin fa-credit-card', ref: pricingSectionRef },
    { id: 6, name: 'contact', selected: false, icon: 'fa-thin fa-messages', ref: contactMeSectionRef },
  ]);



  const isPhoneAppVisible = useIntersectionObserver({ target: phoneAppRef.current, menuNavBarToSelect: menuNavBar[0], menuNavBar: menuNavBar, setMenuNavBar: setMenuNavBar });
  const isWebSiteVisible = useIntersectionObserver({ target: webSiteRef.current, menuNavBarToSelect: menuNavBar[1], menuNavBar: menuNavBar, setMenuNavBar: setMenuNavBar });
  const isTitleVisible = useIntersectionObserver({ target: titleRef.current, menuNavBarToSelect: null});
  const isReviewSectionVisible = useIntersectionObserver({ target: reviewsSectionRef.current, menuNavBarToSelect: menuNavBar[2], menuNavBar: menuNavBar, setMenuNavBar: setMenuNavBar });
  const isPortfolioSectionVisible = useIntersectionObserver({ target: portfiolioSectionRef.current, menuNavBarToSelect: menuNavBar[3], menuNavBar: menuNavBar, setMenuNavBar: setMenuNavBar });
  const isPricingSectionVisible = useIntersectionObserver({ target: pricingSectionRef.current, menuNavBarToSelect: menuNavBar[4], menuNavBar: menuNavBar, setMenuNavBar: setMenuNavBar });
  const isContactMeSectionVisible = useIntersectionObserver({ target: contactMeSectionRef.current, menuNavBarToSelect: menuNavBar[5], menuNavBar: menuNavBar, setMenuNavBar: setMenuNavBar });

  

  function getRandomNumber(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
  }

  function getRandomNumberWithoutFloor(min, max) {
      return Math.random() * (max - min + 1) + min
  }

  const RainDrop = ({left, animationDelay, animationDuration}) => {
      return (
          <div className="container-raindrop" style={{animationDuration: `${animationDuration}s`, zIndex: 0}}>
              <div className="pluie" style={{position: 'absolute', left: `${left}%`, animationDelay: `${animationDelay}s`, animationDuration: `${animationDuration}s`}}></div>
          </div>
      )
  }

  const makeItRain = () => {
      let numberOfRainDropToCreate = 0;
      let rainDrops = []
      while (numberOfRainDropToCreate < numberRainDrop) {
          rainDrops.push(<RainDrop left={getRandomNumber(1, 100)} animationDelay={getRandomNumberWithoutFloor(0, 5)} animationDuration={getRandomNumberWithoutFloor(0.2, 0.3)} />)
          numberOfRainDropToCreate++ 
      }
      return rainDrops
  }

  const handleClickMenu = (menuClicked) => {
    const updateMenuNavBar = menuNavBar.map((item) => {
        if (item.id == menuClicked.id) {
            if (item.ref) {
                scrollToSection(item.ref)
            }
            return {...item, selected: true}
        } else {
            return {...item, selected: false}
        }
    })
    setMenuNavBar(updateMenuNavBar)
}

const scrollToSection = (sectionRef) => {
    slowScrollTo(sectionRef.current.offsetTop, 2000); // Le deuxième argument est la durée (ici 2000 ms soit 2 secondes)
};

const slowScrollTo = (targetPosition, duration) => {
    const startPosition = window.scrollY;
    const distance = targetPosition - startPosition;
    let startTime = null;

    const animation = (currentTime) => {
        if (startTime === null) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const run = ease(timeElapsed, startPosition, distance, duration);
        window.scrollTo(0, run);

        if (timeElapsed < duration) requestAnimationFrame(animation);
    };

    const ease = (t, b, c, d) => {
        // Fonction d'accélération (ease-in-out)
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    requestAnimationFrame(animation);
};



  useEffect(() => {
      const generatedRainDrops = makeItRain();
      setRainDrops(generatedRainDrops)
  }, [])
  

  return (
    <div className="background-rain-container" style={{overflowX: 'hidden', position: 'relative', overflow: 'hidden'}}>
      <div className="flex-row" style={{zIndex: 2, position: 'relative', width: '100vw', boxSizing: 'border-box'}}>
        <main className="main-container flex-column justify-content-center align-items-center max-width-screen">
          <div className="background-radial-theme flex-column justify-content-center align-items-center" style={{width: '100%'}}>
            <div ref={phoneAppRef} className={`content-height fade-in ${isPhoneAppVisible ? 'visible' : ''}`}>
              <h2 className='hightlights-container-title'>Une application mobile ?</h2>
              <CreatePhoneApp />
            </div>
          </div>
        
          <div className="background-radial-theme flex-column justify-content-center align-items-center" style={{width: '100%'}}>
            <div ref={webSiteRef} className={`content-height fade-in ${isWebSiteVisible ? 'visible' : ''}`}>
              <h2 className='hightlights-container-title'>Un site web ?</h2>
              <CreateWebSite />
            </div>
          </div>

          <div className="background-radial-theme flex-column justify-content-center align-items-center" style={{width: '100%'}}>
            <div ref={reviewsSectionRef} className={`content-height fade-in ${isReviewSectionVisible ? 'visible' : ''}`}>
              <h2 className='hightlights-container-title'>Mes avis</h2>
              <ReviewsSection />
            </div>
          </div>

          <div className="background-radial-theme flex-column justify-content-center align-items-center" style={{width: '100%'}}>
            <div ref={portfiolioSectionRef} className={`content-height fade-in ${isPortfolioSectionVisible ? 'visible' : ''}`}>
              <h2 className='hightlights-container-title'>Mes réalisations</h2>
              <PortfolioSection />
            </div>
          </div>

          <div className='link_style_2'>
                <div  
                onClick={() => handleClickMenu(menuNavBar[5])}
                className='link_style_2-text'>
                    On en discute ?
                </div>
                <div>
                    <i style={{fontWeight: 'bold'}} className='link_style_2-icon fa-thin fa-messages'></i>
                </div>
          </div>

          <div className="background-radial-theme flex-column justify-content-center align-items-center" style={{width: '100%'}}>
            <div ref={pricingSectionRef} className={`content-height fade-in ${isPricingSectionVisible ? 'visible' : ''}`}>
              <h2 className='hightlights-container-title'>Tarifs</h2>
              <PricingSection menuNavBar={menuNavBar} setMenuNavBar={setMenuNavBar} />
            </div>
          </div>

          <div className="background-radial-theme flex-column justify-content-center align-items-center" style={{width: '100%'}}>
            <div ref={contactMeSectionRef} className={`content-height fade-in ${isContactMeSectionVisible ? 'visible' : ''}`}>
              <h2 className='hightlights-container-title'>Prenons contact</h2>
              <ContactMeSection />
            </div>
          </div>
        </main>


        <NavBar 
        phoneAppRef={phoneAppRef}
        webSiteRef={webSiteRef}
        reviewsSectionRef={reviewsSectionRef}
        menuNavBar={menuNavBar}
        setMenuNavBar={setMenuNavBar} />

      </div>

      {rainDrops}
      <ScrollLine />
      <Moon /> {/*lune = dark mode, plis tard quand tu clique dessus, ca change et ca met le soleil avec mode clair, par default c'est dark mode*/}
    </div>
  );
}

export default App;
