import React, { useState } from 'react'
import { PRICINGALYFEWEB } from '../utils/Contants'
import PricingCard from './PricingCard'

const PricingSection = ({menuNavBar, setMenuNavBar}) => {

  const [tabSelected, setTabSelected] = useState('web')

  const handleClickMenu = (menuClicked) => {
    const updateMenuNavBar = menuNavBar.map((item) => {
        if (item.id == menuClicked.id) {
            if (item.ref) {
                scrollToSection(item.ref)
            }
            return {...item, selected: true}
        } else {
            return {...item, selected: false}
        }
    })
    setMenuNavBar(updateMenuNavBar)
  }

  const scrollToSection = (sectionRef) => {
    slowScrollTo(sectionRef.current.offsetTop, 2000); // Le deuxième argument est la durée (ici 2000 ms soit 2 secondes)
  };

  const slowScrollTo = (targetPosition, duration) => {
    const startPosition = window.scrollY;
    const distance = targetPosition - startPosition;
    let startTime = null;

    const animation = (currentTime) => {
        if (startTime === null) startTime = currentTime;
        const timeElapsed = currentTime - startTime;
        const run = ease(timeElapsed, startPosition, distance, duration);
        window.scrollTo(0, run);

        if (timeElapsed < duration) requestAnimationFrame(animation);
    };

    const ease = (t, b, c, d) => {
        // Fonction d'accélération (ease-in-out)
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    requestAnimationFrame(animation);
  };


  return (
    <section className='hightlights-container'>
       
        <div className='hightlights-container-section-type-4'>
          <div className='flex-row justify-content-center align-items-center' style={{color: 'white', margin: '20px 0px'}}>
            <div onClick={() => setTabSelected('web')} className={`pricing-tab-left ${tabSelected == 'web' && 'pricing-tab-selected'}`}>WEB</div>
            <div onClick={() => setTabSelected('app')} className={`pricing-tab ${tabSelected == 'app' && 'pricing-tab-selected'}`}>MOBILE</div>
            <div onClick={() => setTabSelected('seo')} className={`pricing-tab ${tabSelected == 'seo' && 'pricing-tab-selected'}`}>SEO</div>
            <div onClick={() => setTabSelected('support')} className={`pricing-tab-right ${tabSelected == 'support' && 'pricing-tab-selected'}`}>MAJ</div>
          </div>
          <div className='container-pricing-card' >
            {PRICINGALYFEWEB.map((item, index) => {
              if (tabSelected == 'web') {
                if (item.type == 'web') {
                  return (
                    <PricingCard pricing={item} />
                  )
                }
              }
              if (tabSelected == 'app') {
                if (item.type == 'app') {
                  return (
                    <PricingCard pricing={item} />
                  )
                }
              }
              if (tabSelected == 'seo') {
                if (item.type == 'seo') {
                  return (
                    <PricingCard pricing={item} />
                  )
                }
              }
              if (tabSelected == 'support') {
                if (item.type == 'support') {
                  return (
                    <PricingCard pricing={item} />
                  )
                }
              }
              
            })}
          </div>

          <div style={{color: 'white', marginTop: 30, textAlign: 'center', width: "90%"}}>Votre projet est <span style={{textDecoration: 'underline'}}>précieux</span>, confiez-le-moi et ensemble, donnons vie à vos idées !</div>
        </div>
    </section>  
    
  )
}

export default PricingSection