import React, { useState } from 'react';

function Phone3D() {

    const [phoneStyle, setPhoneStyle] = useState({
        transition: 'transform 0.1s ease-out',
        animation: 'bounce-phone 5s ease-in-out infinite',
    });

    const handleMouseEnter = () => {
        // j'enlève l'effet bounce-phone
        setPhoneStyle(prevStyle => ({
            ...prevStyle,
            animation: 'none', // Remise à zéro
            transition: 'transform 0.1s ease-out'
        }));
    };

    const handleMouseLeave = () => {

        // remet à 0 le phone 3D avec une animation ease de 1s
        setPhoneStyle(prevStyle => ({
            ...prevStyle,
            transform: `rotateX(0deg) rotateY(0deg)`, 
            transition: `transform 1s ease-out`
        }));
        
        // relance le bounce
        setTimeout(() => {
            setPhoneStyle((prevStyle) => {
                if (prevStyle.transform == `rotateX(0deg) rotateY(0deg)`) {
                    return {
                        ...prevStyle,
                        animation: 'bounce-phone 5s ease-in-out infinite',
                    }
                } else {
                    return {
                        ...prevStyle,
                        animation: 'none',
                    }
                }
            })
        }, 1000)

    };

    const handleMouseMove = (e) => {
        const { clientX, clientY } = e;

        const rotateX = ((clientY - window.innerHeight / 2) / window.innerHeight) * 100; // Ajuster pour la sensibilité
        const rotateY = ((clientX - window.innerWidth / 2) / window.innerWidth) * 100; // Ajuster pour la sensibilité

        setPhoneStyle(prevStyle => ({
            ...prevStyle,
            animation: 'none', // Remise à zéro
            transform: `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`, 
        }));
    };

    return (
        <div
        onMouseMove={handleMouseMove}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        >
            <div 
                className='phone-container'
                style={phoneStyle}
            >
                <div style={{background: 'rgb(225, 226, 228)', height: '100%', width: '100%', borderRadius: 30}}>
                    <div style={{padding: 20}}>
                        <div>
                            <div style={{background: 'lightgray', borderRadius: 5, padding: 10, fontSize: '0.5rem', fontWeight: '500'}}>Paris</div>
                            
                            <div style={{display: 'flex', flexDirection: 'row', marginTop: 7, width: '200%', overflow: 'scroll'}}>
                                <div className='phone-container-cirle-location'>
                                    <i class="fa-light fa-location-dot"></i>
                                </div>
                                <div className='phone-container-cirle-location'>
                                    Paris
                                </div>
                                <div className='phone-container-cirle-location'>
                                    92
                                </div>
                                <div className='phone-container-cirle-location'>
                                    95
                                </div>
                                <div className='phone-container-cirle-location'>
                                    77
                                </div>
                                <div className='phone-container-cirle-location'>
                                    78
                                </div>
                            </div>

                            <div style={{marginTop: 10}}>
                                <div style={{fontSize: '0.5rem', fontWeight: '600'}}>Vidéos aux services des gourmands</div>

                                <div style={{display: 'flex', flexDirection: 'row', width: '200%', marginTop: 7}}>
                                    <div className='phone-container-video-gourmands-container'>
                                        <img style={{height: '100%', width: '100%', objectFit: 'cover'}} src='https://www.you-reserve.com/src/prosvideosshortmarketing/thumbnailvideoshortmarketingpro-3124-2024-05-30-23-13-43-181390.JPG' />
                                    </div>
                                    <div className='phone-container-video-gourmands-container'>
                                        <img style={{height: '100%', width: '100%', objectFit: 'cover'}} src='https://www.you-reserve.com/src/prosvideosshortmarketing/thumbnailvideoshortmarketingpro-3052-2024-01-27-20-22-53-200386.PNG' />
                                    </div>
                                    <div className='phone-container-video-gourmands-container'>
                                        <img style={{height: '100%', width: '100%', objectFit: 'cover'}} src='https://www.you-reserve.com/src/prosvideosshortmarketing/thumbnailvideoshortmarketingpro-4067-2024-05-30-23-32-26-134875.JPG' />
                                    </div>
                                    <div className='phone-container-video-gourmands-container'>
                                        <img style={{height: '100%', width: '100%', objectFit: 'cover'}} src='https://www.you-reserve.com/src/prosvideosshortmarketing/thumbnailvideoshortmarketingpro-3408-2024-02-02-15-14-16-322914.PNG' />
                                    </div>
                                </div>
                            </div>

                            <div style={{marginTop: 10}}>

                                <div style={{display: 'flex', flexDirection: 'row', width: '250%', marginTop: 7}}>
                                    <div className='phone-text-hover-black'>
                                        Tous
                                    </div>
                                    <div className='phone-text-hover-black'>
                                        Ouvert
                                    </div>  
                                    <div className='phone-text-hover-black'>
                                        À tester
                                    </div>
                                    <div className='phone-text-hover-black'>
                                        Franchise
                                    </div>
                                    <div className='phone-text-hover-black'>
                                        Food night
                                    </div>
                                    <div className='phone-text-hover-black'>
                                        Commande à emporter
                                    </div>
                                </div>
                            </div>

                            <div style={{marginTop: 10}}>
                                <div style={{fontSize: '0.5rem', fontWeight: '600'}}>À absolument tester</div>

                                <div style={{display: 'flex', flexDirection: 'row', width: '200%', marginTop: 7}}>
                                    <div className='phone-container-to-test'>
                                        <img style={{height: '100%', width: '100%', objectFit: 'cover'}} src='https://www.you-reserve.com/src/proimagescardview/imagescardviewpro-3114.png' />
                                    </div>
                                    <div className='phone-container-to-test'>
                                        <img style={{height: '100%', width: '100%', objectFit: 'cover'}} src='https://www.you-reserve.com/src/proimagescardview/imagescardviewpro-4175.jpg' />
                                    </div>
                                    <div className='phone-container-to-test'>
                                        <img style={{height: '100%', width: '100%', objectFit: 'cover'}} src='https://www.you-reserve.com/src/proimagescardview/imagescardviewpro-4049.jpg' />
                                    </div>
                                </div>
                            </div>

                            <div className='phone-container-autour-de-vous'>
                                <div style={{fontSize: '0.5rem', fontWeight: '600'}}>Autour de vous</div>

                                <div style={{display: 'flex', flexDirection: 'row', width: '200%', marginTop: 7}}>
                                    <div style={{marginRight: 10, height: '6rem', width: '12rem', background: 'black'}}>
                                        <img style={{width: '100%', height: '100%', objectFit: 'cover'}} src='https://www.you-reserve.com/src/proimagescardview/imagescardviewpro-3302.jpg'/>
                                    </div>
                                    <div style={{marginRight: 10, height: '6rem', width: '12rem', background: 'black'}}>
                                        <img style={{width: '100%', height: '100%', objectFit: 'cover'}} src='https://www.you-reserve.com/src/proimagescardview/imagescardviewpro-3050.PNG'/>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                    </div>


                </div>

            </div>
        </div>
        
    );
}

export default Phone3D;