import React from 'react'
import Laptop3D from './Laptop3D'

const CreateWebSite = () => {
  return (
    <section className='hightlights-container'>
      <div className='hightlights-container-section-type-2'>
        <div className='hightlights-section-type-2-style-1'>
          <Laptop3D />
        </div>
        <div className='hightlights-section-type-2-style-2'>
          <h3 className='hightlights-container-bento-text' style={{width: "40%"}}>Site vitrine</h3>
          <h3 className='hightlights-container-bento-text' style={{width: "20%"}}>Wordpress</h3>
          <h3 className='hightlights-container-bento-text' style={{width: "20%"}}>SEO</h3>
          <h3 className='hightlights-container-bento-text' style={{width: "40%"}}>E-commerce</h3>
          <h3 className='hightlights-container-bento-text' style={{width: "25%"}}>Landing page</h3>
          <h3 className='hightlights-container-bento-text' style={{width: "35%"}}>100% personnalisé</h3>
          <h3 className='hightlights-container-bento-text' style={{width: "40%"}}>Responsive design</h3>
          <h3 className='hightlights-container-bento-text' style={{width: "20%"}}>3D</h3>
          <h3 className='hightlights-container-bento-text' style={{width: "67%"}}>services de paiement (Stripe, PayPal)</h3>
        </div>
      </div>  
    </section>
    
  )
}

export default CreateWebSite