import React, { useRef, useEffect, useState } from 'react';
import './ScrollTest.scss'; // Pour ajouter le style SCSS si nécessaire

const ScrollLine = () => {
  const canvasRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY); // Met à jour la position du scroll
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const { width, height } = canvas;

    // Réinitialise le canvas
    ctx.clearRect(0, 0, width, height);

    // Limiter la longueur maximale de la ligne
    const maxLineLength = height; // Ajusté pour s'assurer que la flèche reste visible
    const lineLength = Math.min(scrollPosition * 0.23, maxLineLength); // Facteur de réduction pour ralentir la vitesse de dessin

    // Variables pour gérer les oscillations de gauche à droite
    const amplitude = 20; // Amplitude de l'oscillation gauche-droite
    const frequency = 50; // Fréquence d'oscillation

    ctx.beginPath();
    ctx.moveTo(width / 2, 0); // Commence au milieu du canvas

    // Tracé progressif avec oscillations
    for (let y = 0; y < lineLength; y += 10) {
      const xOffset = amplitude * Math.sin((y + scrollPosition) / frequency); // Oscillation
      ctx.lineTo(width / 2 + xOffset, y);
    }

    // Ajout des bords arrondis
    ctx.lineCap = 'round'; // Définir les extrémités arrondies

    ctx.strokeStyle = '#724732'; // Couleur de la ligne
    ctx.lineWidth = 4;
    ctx.stroke();

    // Dessiner la flèche à la fin
    if (lineLength >= maxLineLength) {
      drawArrow(ctx, width / 2, lineLength);
    }

  }, [scrollPosition]);

  const drawArrow = (ctx, x, y) => {
    ctx.beginPath();
    ctx.moveTo(x - 10, y - 10); // Partie gauche de la flèche
    ctx.lineTo(x, y); // Pointe de la flèche
    ctx.lineTo(x + 10, y - 10); // Partie droite de la flèche
    ctx.strokeStyle = '#724732'; // Couleur de la flèche
    ctx.lineWidth = 4;
    ctx.stroke();
  };

  return (
    <div className="scroll-line-container">
      <canvas ref={canvasRef} width={window.innerWidth} height={window.innerHeight * 2} />
    </div>
  );
};

export default ScrollLine;