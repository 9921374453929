import React, { useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

const SpinnerStyle1 = ({size, loading}) => {
    return (
        <div>
            <ClipLoader color={'black'} loading={loading} size={size} />
        </div>
    );
};

export default SpinnerStyle1;