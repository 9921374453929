import React, {useState, useEffect} from 'react'

function AlyfeWebText() {

    const [widthAlyfeWeb, setWidthAlyfeweb] = useState(0)


    useEffect(() => {
        const timer = setTimeout(() => {
            setWidthAlyfeweb('100'); // Changer la largeur après un délai
        }, 2000); // Attendre 1 seconde avant d'agrandir
        
        return () => clearTimeout(timer); // Nettoyage du timer
    }, []);
        
  return (
    <div className='alyfe-web-text'>
        <div style={{width: `${widthAlyfeWeb}%`, maxWidth: `${widthAlyfeWeb}%`, overflow: 'hidden', transition: 'width 2s', whiteSpace: 'nowrap', fontFamily: '"League Spartan", sans-serif'}}>
          alyfe web
        </div>
    </div>
  )
}

export default AlyfeWebText