// src/features/contactme/services/contactService.js
export const sendContactInquirieForm = async (formData) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/contact_inquiries`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (!response.ok) {
        throw new Error('Une erreur est survenue lors de l\'envoi du message.');
      }
  
      return { success: true, message: 'Message envoyé avec succès !' };
  
    } catch (error) {
      return { success: false, message: error.message };
    }
};