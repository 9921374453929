/*images*/

import AlyfeWebImage from '../assets/images/alyfeweblogo.jpg'
import ApercuFeljos from '../assets/images/apercufeljos.png'
import ApercuFeljos2 from '../assets/images/apercufeljos2.png'
import ApercuYR from '../assets/images/apercuyr.png'
import ApercuYR2 from '../assets/images/apercuyr2.png'
import ApercuAxway from '../assets/images/apercuaxway.png'

import ApercuAppYR from '../assets/images/apercuappyr.jpg'
import ApercuAppYR2 from '../assets/images/apercuappyr2.jpg'
import ApercuAppYR3 from '../assets/images/apercuappyr3.jpg'
import ApercuAppYR4 from '../assets/images/apercuappyr4.jpg'

import ApercuOsteoGarde from '../assets/images/apercuosteogarde.png'
import ApercuNDP from '../assets/images/apercundp.png'

export const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const IMAGES = {
    AlyfeWebImage,
    ApercuAxway,
    ApercuFeljos,
    ApercuYR,
    ApercuAppYR,
    ApercuAppYR2,
    ApercuAppYR3,
    ApercuAppYR4,
    ApercuFeljos2,
    ApercuOsteoGarde,
    ApercuNDP
}

export const PORTFIOLIOLIST = [
    {
        id: 2, 
        title: 'Feljos',
        url: 'https://feljos.com',
        image: ApercuFeljos,
        type: 'web'
    },
    {
        id: 3, 
        title: 'YouReserve (web)',
        url: 'https://you-reserve.com',
        image: ApercuYR2,
        type: 'web'
    },
    {
        id: 4, 
        title: 'YouReserve (application)',
        url: 'https://you-reserve.com',
        image: ApercuAppYR2,
        type: 'app'
    },
    {
        id: 5, 
        title: 'Notes De Parfums',
        url: 'https://notes-de-parfums.com',
        image: ApercuNDP,
        type: 'web'
    },
    {
        id: 1, 
        title: 'Axway',
        url: 'https://axway.com',
        image: ApercuAxway,
        type: 'web'
    },
    {
        id: 6, 
        title: 'Ostéopathe de garde',
        url: 'https://osteopathe-de-garde.fr',
        image: ApercuOsteoGarde,
        type: 'web'
    },
]

export const REVIEWSONME = [
    {
        id: 1,
        author: 'Bertrand Ledrappier',
        text: <p style={{color: 'lightgray'}}>
            J'ai gardé une opinion tout à fait positive de ma collaboration de plus d'une année avec Abdoulaye en 2022/2023. Abdoulaye Fernandes est un informaticien passionné, qui possède de sérieuses compétences techniques, qu'il approfondit et diversifie en continu au cours de ses missions. Mais c'est également  une personne ouverte, sympathique, qui aime dialoguer et s'intéresse vraiment aux problématiques de ses collègues. C'est donc grâce à ces qualités techniques et relationnelles qu'il enrichit fortement l'équipe avec laquelle il travaille.
        </p>
    },
    {
        id: 2,
        author: 'Pascal Auger',
        text: <p style={{color: 'lightgray'}}>
            J’ai eu grand plaisir ainsi que l’équipe à travailler avec toi. Ta communication était très bonne au sein de l’équipe, efficace, construite et rapide.
Nous avons pu profiter de tes très bonnes compétences en développement en JavaScript, reactJS en particulier, en ce qui nous concerne.
L’environnement existant était loin d’être simple mais tu as pu rentrer rapidement dans le sujet, apporter des évolutions et aussi des améliorations au code existant, améliorations cruciales pour renforcer la stabilité du produit.
Une très belle expérience de collaboration, efficace et dans la bonne humeur 😊
        </p>
    },
    {
        id: 3,
        author: 'Chi-Si D',
        text: <p style={{color: 'lightgray'}}>J'ai eu l'opportunité de collaborer avec cette société pour la création de mon application mobile, mon site internet et je suis extrêmement satisfait du travail fourni. Dès le début, Abdoulaye a su comprendre mes besoins et mes attentes, tout en apportant des suggestions pertinentes pour améliorer l'application.

        Son professionnalisme, sa réactivité et sa capacité à résoudre rapidement les problèmes techniques ont vraiment fait la différence. Avec un code de qualité et une interface utilisateur intuitive. De plus, Alifweb a fait preuve d'une grande flexibilité face aux ajustements que j'ai demandés en cours de projet.
        
        Je recommande vivement Abdoulaye à toute personne ou entreprise cherchant à développer une application mobile ou même un site internet e-commerce ou vitrine, que ce soit pour son expertise technique ou sa capacité à travailler en équipe.
        
        Encore merci pour cette collaboration réussie !</p>
    },
    {
        id: 4,
        author: 'Yat Sam',
        text: <p style={{color: 'lightgray'}}>J’ai eu le plaisir de faire appel à Alyfweb et je recommande vivement ses prestations pour tout projet web, que ce soit pour un site vitrine, un e-commerce ou une application mobile. Sa capacité à comprendre les besoins des clients, à proposer des solutions créatives et efficaces et à respecter les délais est impressionnante. Il est non seulement un expert dans son domaine, mais aussi un véritable partenaire qui cherche toujours à apporter de la valeur ajoutée au projet. C’est un atout précieux pour toute équipe.</p>
    },
]

export const PRICINGALYFEWEB = [
    {
        id: 1,
        name: 'LANDING PAGE',
        price: '900',
        showPrice: true,
        type: 'web',
        options: [
            {
                name: 'Design personnalisé',
            },
            {
                name: 'Page responsive'
            },
            {
                name: 'Optimisation SEO de base'
            },
            {
                name: 'Optimisation de la performance'
            },
            {
                name: 'Intégration de base de sécurité contre le hacking'
            },
        ]
    },
    {
        id: 2,
        name: 'SITE VITRINE',
        price: '1700',
        showPrice: true,
        type: 'web',
        options: [
            {
                name: 'Design personnalisé',
            },
            {
                name: 'Page responsive'
            },
            {
                name: 'Optimisation SEO de base'
            },
            {
                name: 'Optimisation de la performance'
            },
            {
                name: 'Intégration de base de sécurité contre le hacking'
            },
        ]
    },
    {
        id: 3,
        name: 'E-COMMERCE',
        price: '2500',
        showPrice: true,
        type: 'web',
        options: [
            {
                name: 'Design personnalisé',
            },
            {
                name: 'Page responsive'
            },
            {
                name: 'Optimisation SEO de base'
            },
            {
                name: 'Optimisation de la performance'
            },
            {
                name: 'Intégration de base de sécurité contre le hacking'
            },
            {
                name: 'Intégration de solution de paiement'
            },
            {
                name: 'Gestion des commandes'
            },
            {
                name: 'Tableau de bord'
            },
            {
                name: 'Formation à la gestion du commerce'
            },
        ]
    },
    {
        id: 7,
        name: 'E-COMMERCE',
        price: '2500',
        showPrice: true,
        type: 'app',
        options: [
            {
                name: 'iOs & Android',
            },
            {
                name: 'Design personnalisé',
            },
            {
                name: 'Optimisation de la performance'
            },
            {
                name: 'Intégration de base de sécurité contre le hacking'
            },
            {
                name: 'Intégration de solution de paiement'
            },
            {
                name: 'Gestion des commandes'
            },
            {
                name: 'Tableau de bord'
            },
            {
                name: 'Formation à la gestion du commerce'
            },
        ]
    },
    {
        id: 8,
        name: 'RESTAURANT',
        price: '2500',
        showPrice: true,
        type: 'app',
        options: [
            {
                name: 'iOs & Android',
            },
            {
                name: 'Design personnalisé',
            },
            {
                name: 'Optimisation de la performance'
            },
            {
                name: 'Intégration de base de sécurité contre le hacking'
            },
            {
                name: 'Intégration de solution de paiement'
            },
            {
                name: 'Gestion des commandes'
            },
            {
                name: 'Tableau de bord'
            },
            {
                name: 'Formation à la gestion du commerce'
            },
            {
                name: 'Click & collect',
            },
        ]
    },
    {
        id: 6,
        name: '100% PERSONNALISÉ',
        price: 'On en discute',
        showPrice: false,
        type: 'app',
        options: [
            {
                name: 'iOs & Android',
            },
            {
                name: 'Design personnalisé',
            },
            {
                name: 'Optimisation de la performance'
            },
            {
                name: 'Intégration de base de sécurité contre le hacking'
            },
            {
                name: 'Toute vos fonctionnalités réalisées'
            },
        ]
    },
    {
        id: 4,
        name: '100% PERSONNALISÉ',
        price: 'On en discute',
        showPrice: false,
        type: 'web',
        options: [
            {
                name: 'Design personnalisé',
            },
            {
                name: 'Page responsive'
            },
            {
                name: 'Optimisation SEO de base'
            },
            {
                name: 'Optimisation de la performance'
            },
            {
                name: 'Intégration de base de sécurité contre le hacking'
            },
            {
                name: 'Toute vos fonctionnalités réalisées'
            },
        ]
    },
    {
        id: 5,
        name: 'AUDIT SEO',
        price: '500',
        showPrice: true,
        type: 'seo',
        options: [
            {
                name: 'Analyse de l’indexation',
            },
            {
                name: 'Analyse de la performance'
            },
            {
                name: 'Analyse de la compatibilité mobile'
            },
            {
                name: 'Analyse du contenu'
            }
        ]
    },
    {
        id: 9,
        name: 'STRATÉGIE SEO',
        price: 'On en discute',
        showPrice: false,
        type: 'seo',
        options: [
            {
                name: 'Analyse de l’indexation',
            },
            {
                name: 'Analyse de la performance'
            },
            {
                name: 'Analyse de la compatibilité mobile'
            },
            {
                name: 'Analyse du contenu'
            },
            {
                name: "Mise en place d'un stratégie complète sur plusieurs mois"
            }
        ]
    },
    {
        id: 10,
        name: 'SUPPORT & MAJ',
        price: '49',
        showPrice: true,
        type: 'support',
        options: [
            {
                name: 'Maintenance'
            },
            {
                name: 'Mise à jour'
            },
            {
                name: 'Conseils'
            },
            {
                name: 'Veille technologique'
            }
        ]
    }

]

export const PROJETCOLORS = { 
    PRIMARY: '#724732', 
    SECONDARY: '#2c201a', 
    TERTIARY: '#c0917a',
    BLANCCASSE: '#efefef', 
    NEUTRALDARKCOLOR: '#202020', 
    NEUTRALDARKCOLORGRADIENTTOEND: '#111119', 
}



export function openUrlInNewTab(url) {
    var win = window.open(url, '_blank');
    if (win) {
        // La fenêtre a été ouverte avec succès, nous pouvons appeler focus()
        win.focus();
    } else {
        // La fenêtre n'a pas pu s'ouvrir (ex: popup bloqué)
        console.error("La fenêtre n'a pas pu être ouverte. Elle a peut-être été bloquée par un popup blocker.");
    }
}




