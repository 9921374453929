import React from 'react'

function ReviewCard({review}) {
  return (
    <div className='review-card-container'>
        <div style={{width: '100%', fontWeight: 'bold'}}>
            <div style={{color: '#724732'}}>{review.author}</div>
            <div style={{fontWeight: 'normal'}}>
                {review.text}
            </div>
        </div>
    </div>
  )
}

export default ReviewCard