import React, { useState } from 'react';
import { emailPattern } from '../../../utils/Contants';
import SpinnerStyle1 from '../../../components/SpinnerStyle1';
import { sendContactInquirieForm } from '../services.js/contactService';

const ContactForm = () => {
  const [formData, setFormData] = useState({ 
    firstName: '', 
    name: '',
    email: '', 
    tel: '',
    message: '',
  });
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false)

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    const { firstName, name, email, tel, message } = formData;

    if (!firstName || !name || !email || !tel || !message) {
      return 'Tous les champs sont obligatoires.';
    }

    if (!emailPattern.test(email)) {
      return 'Veuillez entrer un email valide.';
    }

    if (tel.length < 10) {
      return 'Veuillez entrer un numéro de téléphone valide (10 chiffres minimum).';
    }

    if (message.length < 10) {
      return 'Veuillez expliquer votre projet avec au minimum 10 caractères.';
    }

    return null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationError = validateForm();
    if (validationError) {
      setErrorMessage(validationError);
      setSuccessMessage('');
      return;
    }

    setIsLoading(true)
    
    const result = await sendContactInquirieForm(formData)

    setIsLoading(false);

    if (result.success) {
      setSuccessMessage(result.message);
      setErrorMessage('');
      setFormData({ firstName: '', name: '', email: '', tel: '', message: '' });
    } else {
      setErrorMessage(result.message);
      setSuccessMessage('');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className='flex-row justify-content-space-between'>
        <div className='input-style-1-container' style={{width: '45%'}}>
          <div className='input-style-1-label'>Nom*</div>
          <input 
          required
          type='text'
          name="name"
          value={formData.name}
          onChange={handleChange}
          className='input-style-1' />
        </div>
        <div className='input-style-1-container' style={{width: '45%'}}>
          <div className='input-style-1-label'>Prénom*</div>
          <input 
          required
          type='text'
          value={formData.firstName}
          name="firstName"
          onChange={handleChange}
          className='input-style-1' />
        </div>
      </div>

      <div className='input-style-1-container'>
        <div className='input-style-1-label'>Email*</div>
        <input     
        required 
        type='email'
        name="email"
        value={formData.email}
        onChange={handleChange}
        className='input-style-1' />
      </div>

      <div className='input-style-1-container'>
        <div className='input-style-1-label'>N° de téléphone*</div>
        <input
        required
        type='text'
        value={formData.tel}
        onChange={handleChange}
        name="tel"
        className='input-style-1' />
      </div>

      <div className='input-style-1-container'>
        <div className='input-style-1-label'>Expliquez votre projet*</div>
        <textarea
        required
        name="message"
        value={formData.message}
        onChange={handleChange}
        className='textarea-style-1' />
      </div>

      <div style={{width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
        <SpinnerStyle1
        loading={isLoading}
        size={10} />
        <button className='button_style_2' type="submit" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: 120}}><div>Envoyer</div> <div><i class="fa-thin fa-paper-plane-top"></i></div></button>
        {successMessage && <p className='sucess_msg'>{successMessage}</p>}
        {errorMessage && <p className='error_msg'>{errorMessage}</p>}
      </div>
    </form>
  );
};

export default ContactForm;