import React, { useState } from 'react'
import { IMAGES } from '../utils/Contants'
import '../styles/GlobalStyles.scss'
import AlyfeWebText from './AlyfeWebText'

function NavBar({phoneAppRef, webSiteRef, reviewsSectionRef, menuNavBar, setMenuNavBar}) {


    const handleClickMenu = (menuClicked) => {
        const updateMenuNavBar = menuNavBar.map((item) => {
            if (item.id == menuClicked.id) {
                if (item.ref) {
                    scrollToSection(item.ref)
                }
                return {...item, selected: true}
            } else {
                return {...item, selected: false}
            }
        })
        setMenuNavBar(updateMenuNavBar)
    }

    const scrollToSection = (sectionRef) => {
        slowScrollTo(sectionRef.current.offsetTop, 2000); // Le deuxième argument est la durée (ici 2000 ms soit 2 secondes)
    };
    
    const slowScrollTo = (targetPosition, duration) => {
        const startPosition = window.scrollY;
        const distance = targetPosition - startPosition;
        let startTime = null;

        const animation = (currentTime) => {
            if (startTime === null) startTime = currentTime;
            const timeElapsed = currentTime - startTime;
            const run = ease(timeElapsed, startPosition, distance, duration);
            window.scrollTo(0, run);

            if (timeElapsed < duration) requestAnimationFrame(animation);
        };

        const ease = (t, b, c, d) => {
            // Fonction d'accélération (ease-in-out)
            t /= d / 2;
            if (t < 1) return (c / 2) * t * t + b;
            t--;
            return (-c / 2) * (t * (t - 2) - 1) + b;
        };

        requestAnimationFrame(animation);
    };

  return (
    <header className='navbar-container'>
        <div className='navbar-container-contact-me'>
            <img src={IMAGES.AlyfeWebImage} className='alyfe-web-logo' alt='alfe-web-logo' />
            <button onClick={() => handleClickMenu(menuNavBar[5])} className='button_style_1' style={{fontSize: '1rem', textAlign: 'center'}}>On en discute ?</button>
            <button className='button_style_1' style={{fontSize: '1rem', textAlign: 'center', display: 'none'}}>Quand tu hover sur ton logo ca met une bulle et un texte</button>
        </div>

        <div className='navbar-container-menu'>
            {menuNavBar.map((item, index) => {
                return (
                    <div key={index} className='navbar-container-icons' style={{strokeDashoffset: item.selected ? 0 : 400}}
                        onClick={() => handleClickMenu(item)}>
                        <svg className='circle' viewBox="0 0 100 100">
                            <circle cx="50" cy="50" r="48" />
                        </svg>
                        <i className={item.icon}></i>
                    </div>      
                )
            })}
        </div>


        <AlyfeWebText />
        

    </header>
  )
}

export default NavBar