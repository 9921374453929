import React from 'react'
import { PORTFIOLIOLIST } from '../utils/Contants'
import PortfolioCard from './PortfolioCard'


const PortfolioSection = () => {
  return (
    <section className='hightlights-container'>
        <div className='hightlights-container-section-type-3'>
          {PORTFIOLIOLIST.map((item, index) => {
            return (
              <PortfolioCard portfolio={item} />
            )
          })}
        </div>
    </section>  
    
  )
}

export default PortfolioSection