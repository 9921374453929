import React, { useState } from 'react';

function Laptop3D() {

    const [phoneStyle, setPhoneStyle] = useState({
        transition: 'transform 0.1s ease-out',
        animation: 'bounce-phone 5s ease-in-out infinite',
    });
 

    return (
            <div 
                className='laptop-container'
                style={phoneStyle}
            >
                <div style={{background: 'transparent', height: '100%', width: '98%'}}>
                    <div style={{background: 'transparent', height: "100%", width: '100%', marginTop: 10}}>
                        <div style={{display: 'flex', flexDirection: 'row', width: '100%', height: '100%'}}>
                            <div style={{width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'rgb(111, 66, 193)', color: 'white', flexDirection: 'column', padding: 30, boxSizing: 'border-box'}}>
                                <div style={{fontFamily: '"League Spartan", sans-serif', fontWeight: 'bold'}}>feljos</div>
                                <div style={{textAlign: 'center', fontSize: '0.5rem', marginTop: '1rem'}}>
                                Augmentez votre visibilité, suivez vos performances et développez votre activité grâce à votre page Feljos.
                                </div>
                            </div>

                            <div style={{width: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'white'}}>
                                <div className='authentication_div'> 

                                    <div className='authentication_label_input'>
                                        Adresse e-mail
                                    </div>

                                    <div className="authentication_div_input">
                                        <i class="fa fa-envelope icon"></i>
                                        <input 
                                        type="text" 
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" 
                                        placeholder="Saisissez votre adresse e-mail" 
                                        required="required"></input>
                                    </div>

                                    <div className='authentication_label_input' aria-label='Mot de passe label'>
                                        Mot de passe
                                    </div>    

                                    <div className="authentication_div_input">
                                        <i class="fas fa-unlock-alt"></i>
                                        <input type="password" placeholder="Mot de passe" required="required"></input>
                                    </div>

                                    <div className='authentication_div_margin_top_style_1'>
                                        <input className="button_authentication_managers" type="submit" value="Se connecter" aria-label='Se connecter label'></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '120%'}}>
                    <div className='laptop-container-keyboard'>
                        <div  style={{width: '15%', height: '50%', background: '#bcbdc1', borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}/>
                    </div>
                </div>
            </div>
        
    );
}

export default Laptop3D;