import React from 'react'
import ReviewCard from './ReviewCard'
import { REVIEWSONME } from '../utils/Contants'

const ReviewsSection = () => {

  return (
    <section style={{padding: '0.5%'}} className='hightlights-container'>
        <div className='hightlights-container-section-type-5 flex-row align-items-center justify-content-flex-start' style={{overflow: 'scroll', height: '100%', width: '100%'}}>
          {REVIEWSONME.map((item, index) => {
            return (
              <ReviewCard review={item} />
            )
          })}
        </div>
    </section>  
    
  )
}

export default ReviewsSection