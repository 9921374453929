import React from 'react'
import Phone3D from './Phone3D'

const CreatePhoneApp = () => {
  return (
    <section className='hightlights-container'>
      <div className='hightlights-container-section-type-1'>
        <div className='hightlights-section-type-1-style-1'>
          <Phone3D />
        </div>
        <div className='hightlights-section-type-1-style-2'>
          <h3 className='hightlights-container-text' style={{padding: 20, background: 'transparent', borderRadius: 20}}>J'utilise des <span className='hightlights-container-text-bold'>outils modernes </span>pour créer des applications mobiles multiplateformes <span className='hightlights-container-text-bold'>(iOS, Android, Windows)</span>.</h3>
          <h3 className='hightlights-container-text' style={{padding: 20, background: 'transparent', borderRadius: 20, marginTop: 0}}>J’intègre des outils essentiels comme la <span className='hightlights-container-text-bold'>caméra</span>, le <span className='hightlights-container-text-bold'>GPS</span> et les <span className='hightlights-container-text-bold'>notifications</span> pour offrir des <span className='hightlights-container-text-bold'>expériences immersives</span> qui tirent parti des applications mobiles.</h3>
          <p style={{color: 'gray', width: '80%', padding: 20, background: 'transparent', borderRadius: 20}}>J’utilise React Native pour créer des applications mobiles multiplateformes qui allient rapidité, performance et design attrayant. En développant une seule base de code pour iOS et Android, je réduis le temps de développement et les coûts, tout en offrant une expérience utilisateur qualitative.</p>
        </div>
      </div>  
    </section>
    
    
  )
}

export default CreatePhoneApp