// useIntersectionObserver.js
import { useEffect, useState } from 'react';

const useIntersectionObserver = (options) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    
                    setIsVisible(true);

                    if (options.menuNavBar) {
                        if (entry.intersectionRatio >= 0.5) {
                            const updateMenuNavBar = options.menuNavBar.map((item) => {
                                if (item.id == options.menuNavBarToSelect.id) {
                                    return {...item, selected: true}
                                } else {
                                    return {...item, selected: false}
                                }
                            })
                            options.setMenuNavBar(updateMenuNavBar)
                        }
                        
                    }
                    

                } else {
                    setIsVisible(false);
                }
            },
            options
        );

        const currentElement = options?.target;
        if (currentElement) {
            observer.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                observer.unobserve(currentElement);
            }
        };
    }, [options]);

    return isVisible;
};

export default useIntersectionObserver;