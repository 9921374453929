import React from 'react'
import { PROJETCOLORS } from '../utils/Contants'

function PricingCard({pricing}) {
  return (
    <div className='pricing-card-container'>
        <div style={{width: '100%', fontWeight: 'bold'}}>
            <div style={{color: '#724732', fontSize: '1.3rem', textAlign: 'left', marginBottom: 20}}>{pricing.name}</div>
            {pricing.type == 'support' ?
              <div style={{fontWeight: 'lighter', fontSize: '1rem', color: 'lightgray', textAlign: 'left'}}>
                <span style={{fontWeight: 'bold'}}>{pricing.price} €</span> / mois
            </div>
            :
            pricing.showPrice ?
              <div style={{fontWeight: 'lighter', fontSize: '1rem', color: 'lightgray', textAlign: 'left'}}>
                  à partir de <span style={{fontWeight: 'bold', color: 'white'}}>{pricing.price} €</span>
              </div>
              :
              <div style={{fontWeight: 'lighter', fontSize: '1rem', color: 'white', textAlign: 'left', fontWeight: 'bold'}}>
                  {pricing.price}
              </div>
            }

            <div style={{height: '1px', width: '100%', background: PROJETCOLORS.PRIMARY, marginTop: 20}} />

            <div style={{marginTop: 20}}>
              {pricing.options.map((option) => {
                return (
                  <div style={{fontWeight: 'normal', color: PROJETCOLORS.TERTIARY, marginTop: 4, fontSize: '0.9rem'}}><i style={{fontWeight: 'bold', color: PROJETCOLORS.PRIMARY}} class="fa-sharp fa-thin fa-badge-check"></i> {option.name}</div>
                )
              })}
            </div>
        </div>
    </div>
  )
}

export default PricingCard