import React from 'react'
import { PROJETCOLORS } from '../utils/Contants'
import ContactForm from '../features/contactme/components/ContactForm'

const ContactMeSection = () => {


  return (
    <section className='hightlights-container'>
      <div className='hightlights-container-section-type-1'>
        <div className='hightlights-section-type-1-style-3'>
          <div>
            <p className='text-style-1'>Nous discuterons de votre projet afin de le réaliser.</p>
          </div>
          <div className='text-style-1'>
            <p className='text-style-1'>Que ce soit un site web ou une application mobile. Alyfe Web saura donner vie à votre idée.</p>
          </div>
          <div className='text-style-1'>
            <p className='text-style-1'>N'hésitez pas à fournir un maximum d'information. Si vous avez des images ou vidéos pour illustrer votre idée, vous pourrez me les envoyer après une première prise de contact.</p>
          </div>
        </div>
        <div className='hightlights-section-type-1-style-4'>
          <ContactForm />
        </div>
      </div>  
    </section>
    
    
  )
}

export default ContactMeSection